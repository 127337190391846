<template>
    <v-container>
        <welcome-banner v-if="$vuetify.breakpoint.mdAndUp" class="mt-4 mb-7" />

        <v-row class="mt-2 mb-0 px-3">
            <div class="text-h6 text-md-h5 font-weight-bold">
                Profile Settings
            </div>
            <v-spacer />
            <quick-links :profile="profile" />
        </v-row>
        <v-row>
            <v-col
                :cols="$vuetify.breakpoint.xs ? 2 : 1"
                md="3"
                xl="2"
                :class="{
                    'd-flex justify-center pr-0 pt-0':
                        $vuetify.breakpoint.smAndDown
                }"
            >
                <sections-drawer
                    :user="user"
                    :sections="sections"
                    :loading="isLoading"
                    :context="context"
                    class="main-background"
                >
                    <v-list-item class="justify-center" @click.prevent="logout">
                        <v-list-item-icon
                            :class="{ 'mr-3': $vuetify.breakpoint.mdAndUp }"
                            class="justify-center"
                        >
                            <v-icon small style="width: 20px">
                                sign-out-alt
                            </v-icon>
                        </v-list-item-icon>
                        <v-list-item-content>
                            <v-list-item-subtitle class="secondary--text">
                                Logout
                            </v-list-item-subtitle>
                        </v-list-item-content>
                    </v-list-item>
                </sections-drawer>
            </v-col>
            <v-col
                :cols="$vuetify.breakpoint.xs ? 10 : 11"
                md="9"
                xl="10"
                :class="{ 'py-0': $vuetify.breakpoint.smAndDown }"
            >
                <personal-information
                    v-if="card === 'personal'"
                    @updated="update"
                />

                <security v-if="card === 'security'" />

                <terms-of-service v-if="card === 'terms-of-service'" />

                <credits
                    v-if="card === 'credits'"
                    :is-checking-payment="autoCheckPaymentInterval"
                />

                <payments v-if="card === 'payments'" />

                <subscriptions v-if="card === 'payments'" />

                <whitelabel v-if="card === 'whitelabel'" />

                <companies v-if="card === 'companies'" />
            </v-col>
        </v-row>
        <become-affiliate-form-modal />
    </v-container>
</template>

<script lang="ts">
import Vue from 'vue';
import Component from 'vue-class-component';

import { WelcomeBanner } from '@/components/WelcomeBanner';
import { BecomeAffiliateFormModal } from '@/components/Affiliates';

import SectionsDrawer from '@/pages/Profile/SectionsDrawer.vue';
import QuickLinks from '@/pages/Profile/QuickLinks';

import ProfileSections from '@/pages/Profile/sections';

import type { QuickLinksData, ProfileUser } from '@/types/User';
import type { ProfileData } from '@/types/Panel';

@Component({
    components: {
        WelcomeBanner,
        SectionsDrawer,
        QuickLinks,
        ...ProfileSections,
        BecomeAffiliateFormModal
    }
})
export default class Profile extends Vue {
    isLoading = true;
    user: ProfileUser = {};
    autoCheckPaymentInterval: ReturnType<typeof setInterval> | null = null;

    options = {
        credits: {
            available: false,
            presscable: 0
        },
        payments: {
            available: false
        }
    };

    sections = [
        {
            id: 'personal',
            label: 'Personal Information',
            icon: 'user-circle',
            enabled: () => true
        },
        {
            id: 'security',
            label: 'Security',
            icon: 'user-shield',
            enabled: () => true
        },
        {
            id: 'credits',
            label: 'Credits',
            icon: 'credit-card',
            enabled(context: InstanceType<typeof Profile>) {
                return context.options.credits.available;
            }
        },
        {
            id: 'payments',
            label: 'Payments',
            icon: 'money-bill',
            enabled(context: InstanceType<typeof Profile>) {
                return context.options.payments.available;
            }
        },
        {
            id: 'whitelabel',
            label: 'Whitelabel',
            icon: 'briefcase',
            enabled: () => {
                return this.$can('manage', 'WhiteLabel');
            }
        },
        {
            id: 'companies',
            label: 'Companies',
            icon: 'building',
            enabled: () => {
                return this.$can('manage', 'Companies');
            }
        },
        {
            id: 'users',
            label: 'Users',
            icon: 'users',
            link: '/users/',
            enabled: () => {
                return this.$can('manage', 'Users');
            }
        },
        {
            id: 'clients',
            label: 'Clients',
            icon: 'users',
            link: '/users/',
            enabled: () => {
                return this.$can('manage', 'Clients');
            }
        },
        {
            id: 'terms-of-service',
            label: 'Terms of Service',
            icon: 'users',
            enabled: () => true
        }
    ];

    profile: QuickLinksData | null = null;

    get card() {
        return (
            this.isLoading ||
            //this.$route.params.subsection ||
            this.$route.params.section
        );
    }

    get context() {
        return this;
    }

    setLoading(isLoading = true) {
        this.isLoading = isLoading;
    }

    mounted() {
        this.setLoading();

        this.getData()
            .then(({ data }) => {
                this.setData(data);
            })
            .finally(this.setLoading.bind(this, false));

        if (+this.$route.query.upgrade) {
            this.autoCheckPaymentInterval = setInterval(
                this.autoCheckPayment.bind(this),
                5000
            );
        }
    }

    beforeDestroy() {
        if (this.autoCheckPaymentInterval) {
            clearInterval(this.autoCheckPaymentInterval);
        }
    }

    setData(data?: ProfileData) {
        if (data) {
            this.user = data.user;

            this.options.credits = data.credits;
            this.options.payments = data.payments;

            this.profile = {
                ...data.site,
                ...data.reseller,
                credits: data.credits,
                isActiveCustomer: data.isActiveCustomer,
                isActiveAnnouncementCustomer: data.isActiveAnnouncementCustomer,

                hasTraining: data.hasTraining,
                hasAsaa: data.hasAsaa,
                isAsigoBuyer: data.isAsigoBuyer,
                isDropServeBuyer: data.isDropServeBuyer
            };
        }
    }

    async getData() {
        return this.$http
            .get('/users/panel')
            .then(({ data }) => data)
            .catch(error => {
                if (!error.isIntercepted) {
                    this.$store.dispatch('notification/error', error);
                }

                return {};
            });
    }

    update(data: ProfileUser) {
        this.user = {
            ...this.user,
            ...data
        };
    }

    async logout() {
        await this.$store.dispatch('user/logout');

        this.$router.push('/login');
    }

    autoCheckPayment() {
        this.getData().then(({ data }) => {
            if (data) {
                const creditHasBeenUpgraded =
                    data.credits.presscable !== this.options.credits.presscable;

                if (creditHasBeenUpgraded) {
                    this.setData(data);

                    if (this.autoCheckPaymentInterval) {
                        clearInterval(this.autoCheckPaymentInterval);
                    }

                    this.$store.dispatch(
                        'notification/success',
                        'Payment received. You may continue and publish.'
                    );
                }
            }
        });
    }
}
</script>
