import type { QuickLinksData, QuickLinkData } from '@/types/User';
import LinksPool from '../LinksPool';

export default (data: QuickLinksData) => {
    const links: QuickLinkData[] = [
        LinksPool.asigo_sales_funnel_settings,
        LinksPool.custom_pricing_link
    ];

    if (data.credits.premium) {
        links.push(LinksPool.premium_credits_sales_page);
    }

    if (data.credits.crypto) {
        links.push(LinksPool.defi_credits_sales_page);
    }

    if (data.credits.blow) {
        links.push(LinksPool.blow_packs_sales_page);
    }

    if (data.credits.msn) {
        links.push(LinksPool.msn_sales_page);
    }

    // hide until further notice
    // links.push(LinksPool.become_an_affiliate, LinksPool.affiliate_login);

    return links;
};
